.container {
  max-width: 960px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  padding: 0 8px;
}

.title {
  width: 100%;
  text-align: center;
}

.question {
  width: 100%;
}

.textBox {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.imageQuestion {
  width: 100%;
  display: flex;
  align-items: center;
}

.imageQuestionText {
  margin-left: 20px;
}

.icon {
  font-size: 96px;
  margin: 20px auto 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
              0px 2px 2px 0px rgba(0, 0, 0, 0.14),
              0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.choices {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: #eeeeee;
}

.imageBox {
  padding: 20px;
  margin-bottom: 10px;
}

.right {
  color: #54b948;
}

.wrong {
  color: #e82c0c;
}

.answer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.button {
  background: #4285f4;
  padding: 10px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
}

.button:hover {
  filter: brightness(120%);
}
