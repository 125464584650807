.page {
  max-width: 1500px;
  margin: auto;
  /* allows the content to be seen passed the nav bar */
  margin-bottom: 44px;
}

.navRight {
  position: fixed;
  bottom: 8px;
  z-index: 99;
}

.search {
  display: flex;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.40);
}
.search input {
  border: none;
  height: 40px;
  font-family: 'roboto_mono';
  padding: 0 4px;
}
.search input:focus{
  outline: none;
}
.search button {
  background: gray;
  border: none;
  cursor: pointer;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.search button:hover {
  background: #ccc;
}
.search button i {
  font-size: 24px;
}
