.title {
  display:flex;
  align-items:center;
  justify-content:center;
  max-width: 230px;
  text-align: center;
}

.wrapper {
  display:flex;
  align-items:center;
  justify-content:center;
}

.porthole {
  border: 15px solid #c4d4e0;
  border-radius: 200px;
  height: 200px;
  width: 200px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.80);
  margin-top: 8px;
}

.porthole img {
  height: 200px;
  width: auto;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

.porthole a {
  border-radius: 200px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.80);
  display: block;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  text-indent: -9999px;
}
