.title {
  margin-left: 8px;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
}

.itemLeft {
  margin-bottom: 8px;
  flex-grow: 0;
}
.itemRight {
  flex-grow: 0;
}

@media (min-width: 1280px) {
  .itemRight > div {
    margin-left: 8px;
  }
}
