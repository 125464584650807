.layout {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 600px) {
  .alternative {
    display: none;
  }
}

@media (max-width: 600px) {
  .document {
    display: none;
  }
}

.button {
  display: block;
  width: 115px;
  height: 25px;
  background: #4285f4;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  line-height: 25px;
  margin-top: 8px;
}

.button:hover {
  filter: brightness(120%);
}

.docPage {
  margin-top: 8px;
}
