.navLeft {
  position: fixed;
  bottom: 8px;
  z-index: 99;
}

.navLeft a {
  background-color: gray;
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.40);
  text-decoration: none;
}

.navLeft i {
  color: white;
  font-size: 24px;
}

.navLeft a:hover, .navLeft i:hover {
  background-color: #ccc;
}
