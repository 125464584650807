.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}

.item {
  padding-left: 8px;
  padding-right: 8px;
}
