@font-face {
  font-family: 'roboto_mono';
  src: url('fonts/robotomono-italic-variablefont_wght-webfont.woff2') format('woff2'),
       url('fonts/robotomono-italic-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;

}

@font-face {
  font-family: 'roboto_mono';
  src: url('fonts/robotomono-variablefont_wght-webfont.woff2') format('woff2'),
       url('fonts/robotomono-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  padding: 0;
  font-family: 'roboto_mono';
}

html {
  background-color: palegoldenrod;
}

/* HACK: Gallery/Album page: position play/fullscreen buttons at the top
to avoid clashing with the home/search controls */
button.image-gallery-play-button,
button.image-gallery-fullscreen-button {
  bottom: auto;
  top: 0;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
