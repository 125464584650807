.container {
  max-width: 800px;
  margin: auto;
}

.description {
  margin-bottom: 16px;
}

.description p {
  margin: 0 0 8px 0;
}

.description p:last-child {
  margin: 0;
}

.wrapper {
  /* TODO make a better responsive table */
  overflow-x:auto;
  margin-bottom: 16px;
}

.table {
  width: 100%;
}

.table td {
  display: table-cell;
  padding: 16px;
  text-align: left;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.table tr:last-child td {
  border-bottom: 0;
}
